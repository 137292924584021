import React, { useContext } from "react"
import "./_success-pages-unsubscribe.scss"
import { string_translation, createMarkup } from "../../../utils"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import {Link, useStaticQuery, graphql} from "gatsby"

function SucessPagesUnsubscribe(props) {
  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)

  const {stringTranslations} = useStaticQuery(graphql`query {
    stringTranslations {
      goBack_ar:translateString(language: AR, string: "الصفحة الرئيسية")
      goBack_en:translateString(language: EN, string: "Back Home")

      home_ar:translateString(language: AR, string: "HOMEPAGE NAME")
      home_en:translateString(language: EN, string: "HOMEPAGE NAME")

      title1_ar:translateString(language: AR, string: "شكرًا لك!")
      title1_en:translateString(language: EN, string: "Thank You!")

      title2_ar:translateString(language: AR, string: "تم إلغاء الاشتراك بنجاح")
      title2_en:translateString(language: EN, string: "You've successfully unsubscribed!" )


      title3_ar:translateString(language: AR, string: "لن تستقبل أي تحديثات على الأدلة التطبيقية للكود العمراني الخاص بالمسار الرياض بعد الآن ")
      title3_en:translateString(language: EN, string: "You no longer will receive any updates on design code manuals. ")
    }
  }`)

  return (
    <>
      <section className="success-prompt-download">
        <div className="container">
          <div className="success-wrapper">
            <div className="img-wrapper desktop-show">
              <img src={props.image1} alt={ string_translation(stringTranslations,"title1", locale)} />
            </div>
            <div className="img-wrapper mobile-show">
              <img src={props.image1_mobile} alt={ string_translation(stringTranslations,"title1", locale)} />
            </div>
            <div className="big-text-title" >
              <span dangerouslySetInnerHTML={createMarkup(
                      string_translation(stringTranslations,"title1", locale)
                    )}>
                    </span>
            </div>
            <br />
            <div className="big-text">{string_translation(stringTranslations,"title2", locale)}
           
<p>{props.email}</p>
          
            {string_translation(stringTranslations,"title3", locale)}
            </div>
            <div className="back-home">
           
              <Link to={"/"+locale} className="click-home">
                {" "}
                &#8592;  {string_translation(stringTranslations,"goBack", locale)}
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default SucessPagesUnsubscribe
