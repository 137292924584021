import * as React from "react"
import Navbar from "../components/Navbar"
import SuccessPageUnsubscribe from "../components/success-pages-unsubscribe/SuccessPagesUnsubscribe"
import Footer from "../components/Footer/index"
import "../components/error-pages/_error-page.scss"

 const  SucessPageUnsubscribe = ({ location }) => {
  let props = {
    image1:  "/images/success-pages/success.svg",
    image1_mobile:  "/images/success-pages/success.svg",
    title1: (
      <>
        <span> thank you!</span>
      </>
    ),
  }
  const params = new URLSearchParams(location.search);
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  if(validateEmail(params.get("email"))){
    props.email = params.get("email");
  }
 
  return (
    <>
      <Navbar />
        <SuccessPageUnsubscribe {...props} />
      <Footer />
    </>
  )
}

export default SucessPageUnsubscribe